import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app';
import Footer from './components/footer';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import './styles/global.scss';
import { BrowserRouter } from 'react-router-dom';
const isIE = require('./utils/is-ie');

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
ReactDOM.render(
  <React.StrictMode>
    <Footer />
  </React.StrictMode>,
  document.getElementById('footer')
);
if(isIE) {
  document.getElementById('swirler').style.display = 'none';
} else{
  document.getElementById('swirler').style.display = 'block';
}

/* window.addEventListener("load", function(){
  
}); */

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
