import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import back_icon from '../../images/back-icon.svg';
import close_icon from '../../images/cross-icon.svg';
import { ReactComponent as DropDownOffIcon } from '../../images/dropdown-off.svg';
import expand_icon from '../../images/expand-icon.svg';
import { setCurrentSolution, setIsCameraAnimating, store as globalState } from "../../redux/store";
import * as BabylonUtils from '../../utils/babylon-utils';
import * as _get from 'lodash/get';
import HCDB from '../../utils/data-store';
import './solution.scss';

let travelAnimationPromise = null;

class Solution extends React.Component {

  constructor(props) {
    super();
    this.db = new HCDB();
    this.state = {
      scene: null,
      prevPage: '',
      challenge: this.db.fetchChallenge(props.urlParams.id),
      isCollapsed: true,
      calendarVisible: false
    }
  }

  onPageLoaded() {
    let verticals = this.db.fetchVerticals(this.state.challenge);

    /* Highlighted necessary buildings */
    let sceneComponent = _get(this.props.sceneComponent, 'current', null);
    if (sceneComponent) {
      verticals.forEach(vertical => {
        this.db.fetchBuildings(vertical).forEach(building => {
          sceneComponent.highlightBuilding(vertical, building);
        });
      });
      sceneComponent.clearOnScreenVerticalButtons();

      let solutions = this.db.fetchSolutionsForVertical(this.state.challenge, this.props.urlParams.verticalid);

      let vertical = this.db.fetchVertical(this.props.urlParams.verticalid);

      let building = this.db.fetchBuilding(vertical, this.props.urlParams.buildingid);

      /* Start flying camera to internal position */
      if (building) {

        let animation = sceneComponent.createCameraAnimationSequence(building, false);
        if (animation) {
          globalState.dispatch(setIsCameraAnimating({ isCameraAnimating: true }));
          travelAnimationPromise = BabylonUtils.makeCancelablePromise(animation);
          travelAnimationPromise.promise.then(() => {
            globalState.dispatch(setIsCameraAnimating({ isCameraAnimating: false }));
            sceneComponent.addBuildingHotspots(solutions, building);
          }).catch((reason) => console.log(reason));
        }
      }
    }

  }

  onNextFrame(callback) {
    setTimeout(function () {
      requestAnimationFrame(callback)
    })
  }

  /* Page has been loaded directly, wait until the scene is loaded before prepping. */
  onSceneLoaded(readyScene) {
    this.onPageLoaded();
    this.setState({ scene: readyScene });
  }

  navigateToPage(id) {
    this.props.navigator.push('/' + id);
  }

  toggleCalendarVisible = (evt, visibleState = null) => {
    let sceneComponent = _get(this.props.sceneComponent, 'current', null);
    let newState = visibleState !== null ? visibleState : !this.state.calendarVisible;
    if (sceneComponent) {
      sceneComponent.blockCanvas(newState);
    }
    this.setState({ calendarVisible: newState });
    if (newState) {
      this.trackClick('Button', 'Contact TSE - ' + (this.props.selectedSolution.name || 'Unknown'));
    }
  }

  toggleCollapse = (evt, toggleState = null) => {
    let newState = toggleState !== null ? toggleState : !this.state.isCollapsed;
    this.setState({ isCollapsed: newState });
  }

  closeSelf = () => {
    let sceneComponent = _get(this.props.sceneComponent, 'current', null);
    if (sceneComponent) {
      this.toggleCalendarVisible(null, false);
      sceneComponent.closeActiveHotspot();
    }
  }

  trackClick(category, label) {
    window.gtag('event', 'click', { 'event_category': category, 'event_label': label });
  }

  render() {

    return (
      <>
        <div className="d-flex flex-row mt-5">
          <Link to={"/" + this.props.urlParams.id} className="Solution__back no-select"><div className="Solution__back-container"><img src={back_icon} alt="Go back" /> <h2 className="text-black mb-0">Back to challenge overview</h2></div></Link>
        </div>
        <div className="d-flex flex-column flex-lg-row h-100 mt-3 mb-5">
          {this.props.selectedSolution && <div className="content-pane Solution__content-pane Solution__page">

            <div className="Solution__nav no-select" onClick={this.closeSelf} style={{ opacity: (this.props.selectedSolution ? 1 : 0), cursor: (this.props.selectedSolution ? 'pointer' : 'initial'), pointerEvents: (this.props.selectedSolution ? 'all' : 'none') }}>
              <img src={this.state.isCollapsed ? expand_icon : close_icon} alt="Collapse pane" />
            </div>
            {!this.state.isCollapsed && <div className="Solution__content" >
              <h1 className="text-black-italic">{this.props.selectedSolution.name}</h1>
              {/* <h2 className="text-semi-bold">my subtitle</h2> */}
              <p className="text-semi-bold" dangerouslySetInnerHTML={{ __html: this.props.selectedSolution.description }}></p>
              <p className="text-pink">Do you have an opportunity you’d like to discuss? Request a meeting with our Technology Solutions Experts.</p>
              <button className="btn btn-hc mt-3 mb-4" onClick={this.toggleCalendarVisible}><p className="mr-5">Contact TSE</p><DropDownOffIcon className="dropdown-icon" alt="Contact TSE Icon" /></button>
            </div>}
            {this.state.isCollapsed && <div className="Solution__content collapsed"> <h2 className="text-semi-bold mb-0">{this.props.selectedSolution !== null ? 'Click the arrow to expand' : 'Select a solution to read more.'}</h2> </div>}
          </div >
          }
          {this.state.calendarVisible && <div className="Solution__content-pane Solution__calendar">
            <div className="Solution__nav no-select" onClick={this.toggleCalendarVisible}>
              <img src={close_icon} alt="Collapse pane" />
            </div>
            <div className="Solution__content collapsed"><iframe src={`https://tse.westcoast.co.uk/team-member/${this.props.selectedSolution?.contact}/calendar`} width="100%" allowFullScreen="" frameBorder="0">
            </iframe></div>
          </div>}
        </div>
      </>
    );
  }

  componentWillUnmount = () => {
    if (travelAnimationPromise !== null) {
      travelAnimationPromise.cancel();
    }
    let sceneComponent = _get(this.props.sceneComponent, 'current', null);
    if (sceneComponent) {
      sceneComponent.clearOnScreenSolutionHotspots();
    }
    globalState.dispatch(setCurrentSolution({ solution: null }));
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.isSceneLoaded !== this.props.isSceneLoaded && this.props.isSceneLoaded) {
      this.onSceneLoaded(this.props.scene);
    }
    if (prevProps.selectedSolution !== this.props.selectedSolution && this.props.selectedSolution !== null) {
      this.toggleCollapse(null, false);
    }
  }

  componentDidMount = () => {
    /* Page has been navigated to. Scene should be ready to go. */
    if (this.props.isSceneLoaded) {
      this.onPageLoaded();
    }
  }

}

const mapStateToProps = function (state) {
  return {
    currentChallengeID: state.currentChallengeID,
    selectedSolution: state.selectedSolution
  }
}

export default connect(mapStateToProps)(Solution);