import { ActionManager, ExecuteCodeAction } from '@babylonjs/core/Actions'
import { Color3, Vector3 } from '@babylonjs/core/Maths/math'
import { SceneLoader } from '@babylonjs/core/Loading/sceneLoader'
import { Tools } from '@babylonjs/core/Misc/tools'
import "@babylonjs/core/Debug/debugLayer";
import "@babylonjs/inspector";
import * as GUI from 'babylonjs-gui';
import delay from 'delay';
import * as _ from 'lodash';
import PQueue from 'p-queue';
import React from 'react';
import { connect } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import KEY_CODE from '../../classes/keycodes';
import { setCurrentSolution, store as globalState } from "../../redux/store";
import * as BabylonUtils from '../../utils/babylon-utils';
import * as constants from '../../utils/const';
import SceneComponent from './scene-builder.jsx';
import './scene.scss';


class Scene extends React.Component {

  camera = null;
  canvas = null;
  currentFlyingCircleIndex = 0;
  onScreenVerticalButtons = [];
  onScreenSolutionHotspots = [];
  sceneRotationQueue = null;


  constructor(props) {
    super();
    this.sceneRotationQueue = new PQueue({ concurrency: 1 });
    this.state = {
      highlightMaterial: null,
      sceneDebugEnabled: false,
      scene: null,
      camera: null,
      sceneOptimizer: null,
      sceneOptimizerStarted: false,
      sceneUI: null,
      sceneCanvasRef: null,
      currentBuilding: null
    }
  }

  /**
   * 
   * @param {Object} destination 
   */
  createCameraAnimationSequence(destination) {
    return BabylonUtils.createCameraAnimationSequence(this.state.scene, this.camera, destination)
  }

  /**
   * 
   * @param {Object} destination 
   */
  createCameraAnimation(destination, rotation, speedRatio = 1) {
    return BabylonUtils.createCameraAnimation(this.state.scene, this.camera, destination, rotation, speedRatio);
  }

  closeActiveHotspot = () => {
    this.onScreenSolutionHotspots.forEach(existingHotspot => {
      if (BabylonUtils.isHotspotActive(existingHotspot.control)) {
        BabylonUtils.toggleHotspot(existingHotspot.control, false);
      }
    });
    globalState.dispatch(setCurrentSolution({ solution: null }));
  }

  onHotspotClicked = (clickedSolution, building, clickedHotspot) => {
    BabylonUtils.toggleHotspot(clickedHotspot);
    globalState.dispatch(setCurrentSolution({ solution: this.props.selectedSolution && !BabylonUtils.isHotspotActive(clickedHotspot) ? null : clickedSolution }));
    this.onScreenSolutionHotspots.forEach(existingHotspot => {
      if (existingHotspot.control.name !== clickedHotspot.name) {
        if (BabylonUtils.isHotspotActive(existingHotspot.control)) {
          BabylonUtils.toggleHotspot(existingHotspot.control, false);
        }
      }
    });
  }

  trackClick(category, label) {
    window.gtag('event', 'click', { 'event_category': category, 'event_label': label });
  }

  onBuildingClicked = (vertical, building) => {
    this.trackClick('Building', this.props.currentChallengeID + '/' + vertical._id + '/' + building._id);
    if (building.animation) {
      this.props.navigator.push(this.props.currentChallengeID + '/' + vertical._id + '/' + building._id);
    } else {
      alert("Content for the " + building.name + ' building is not available yet!');
    }
  }

  onKeyPressDown = (scene, keyCode) => {
    if (keyCode[KEY_CODE.SPACE]) {
      if (this.camera) {
        if (constants.enableFlyingCamera) {
          this.camera.cameraDirection.y += 3;
        }
      }
    }
    if (keyCode[KEY_CODE.KEY_X]) {
      console.log("Rotation Degrees");
      console.log(this.camera.inputs)
      var DirX = Tools.ToDegrees(this.camera.rotation.x);
      var DirY = Tools.ToDegrees(this.camera.rotation.y);
      var DirZ = Tools.ToDegrees(this.camera.rotation.z);
      console.log(DirX + ', ' + DirY + ', ' + DirZ)
      console.log("Position");
      console.log(this.camera.position);
      console.log('[' + this.camera.position.x.toFixed(2) + ', ' + this.camera.position.y.toFixed(2) + ', ' + this.camera.position.z.toFixed(2) + ']');
      console.log("Rotation");
      console.log(this.camera.rotation);
      console.log('[' + this.camera.rotation.x.toFixed(2) + ', ' + this.camera.rotation.y.toFixed(2) + ', ' + this.camera.rotation.z.toFixed(2) + ']');
    }

  }

  /**
   * vertical-data.json - challenge-data.json
   * 
   * @param {Object} solutions 
   * @param {Object} building 
   */
  addBuildingHotspots(solutions, building) {
    this.setState({ currentBuilding: building });
    for (let i = 0; i < solutions.length; i++) {
      const solution = solutions[i];
      if (building.hotspots[i]) {
        let hotspotButton = BabylonUtils.createHotspotButton(solution, building, this.onHotspotClicked);
        this.state.sceneUI.addControl(hotspotButton);
        this.onScreenSolutionHotspots.push({ position: building.hotspots[i], control: hotspotButton });
      }
    }
    this.onScreenSolutionHotspots.forEach(hotspot => {
      if (this.state.scene) {
        BabylonUtils.CreateAndStartFadeInAnimation(this.state.scene, hotspot.control, undefined, 1.6);
      }
    });
  }

  /**
   * vertical-data.json - challenge-data.json
   * 
   * @param {Object} vertical 
   * @param {Object} building 
   */
  highlightBuilding(vertical, building) {
    let scene = this.state.scene;
    let sceneGUI = this.state.sceneUI;
    if (scene && sceneGUI) {
      let button = BabylonUtils.createOverlayButton(sceneGUI.getContext(), vertical, building, this.onBuildingClicked);
      let parentMesh = scene.getNodeByName(building.mesh).getChildMeshes()[0];
      sceneGUI.addControl(button);
      button.linkWithMesh(parentMesh);
      button.linkOffsetY = -(parentMesh.scaling.y) + -50;
      BabylonUtils.CreateAndStartFadeInAnimation(scene, button, undefined, 1.6);
      this.onScreenVerticalButtons.push(button);
      scene.getNodeByName(building.mesh).getChildMeshes().forEach(model => {
        if (model.material.id === 'White') {
          model.material = this.state.highlightMaterial;
        }
      });
    }
  }

  /**
   * Remove from screen all current in-city 'vertical' buttons
   */
  clearOnScreenVerticalButtons() {
    let scene = this.state.scene;
    let sceneGUI = this.state.sceneUI;
    if (scene && sceneGUI) {
      for (let i = 0; i < this.onScreenVerticalButtons.length; i++) {
        sceneGUI.removeControl(this.onScreenVerticalButtons[i]);
      }
      this.onScreenVerticalButtons = [];
    }
  }

  /**
   * Remove from screen all current in-building hotspot buttons
   */
  clearOnScreenSolutionHotspots() {
    let scene = this.state.scene;
    let sceneGUI = this.state.sceneUI;
    if (scene && sceneGUI) {
      for (let i = 0; i < this.onScreenSolutionHotspots.length; i++) {
        sceneGUI.removeControl(this.onScreenSolutionHotspots[i].control);
      }
      this.onScreenSolutionHotspots = [];
      this.setState({ currentBuilding: null });
    }
  }

  /**
   * Remove green highlight effect from all buildings.
   */
  clearBuildingHighlights() {
    if (this.state.scene) {
      this.state.scene.meshes.forEach(mesh => {
        if (BabylonUtils.hasAlbedo(mesh, new Color3.FromHexString(constants.highlightColor))) {
          mesh.material = this.state.scene.getMaterialByName('White');
        }
      });
      this.clearOnScreenVerticalButtons();
      this.clearOnScreenSolutionHotspots();
    }
  }

  /**
   * When toggled, reduces canvas resolution by 30% to improve rendering performance and increase framerate.
   */
  toggleSceneOptimizer() {
    let scene = this.state.scene;
    if (scene) {
      let isSceneOptimizerStarted = !this.state.sceneOptimizerStarted;
      let hwScalingLevel = isSceneOptimizerStarted ? (constants.defaultScalingLevel * 0.3) + constants.defaultScalingLevel : constants.defaultScalingLevel;
      this.props.onSceneOptimizerStarted(isSceneOptimizerStarted);
      this.setState({ sceneOptimizerStarted: isSceneOptimizerStarted });
      scene.getEngine().setHardwareScalingLevel(hwScalingLevel);
    }
  }

  /**
   * Rotate the camera around the scene to the next vantage point position. Once reaching the final position. Go back the other way.
   * If a rotation is already ongoing the next one will be added to a queue with a 2 second delay to allow the previous one to finish.
   */
  rotateScene() {
    if (this.state.scene && this.camera && !this.isInBuilding()) {
      if (this.sceneRotationQueue.size < 7) {
        this.sceneRotationQueue.add(() => BabylonUtils.createCameraAnimation(this.state.scene, this.camera, constants.flyingCirclePositions[this.currentFlyingCircleIndex], constants.flyingCircleRotations[this.currentFlyingCircleIndex])).then(() => {
          this.currentFlyingCircleIndex++;
          if (constants.flyingCirclePositions.length == this.currentFlyingCircleIndex) { this.currentFlyingCircleIndex = 1; constants.flyingCirclePositions.reverse(); constants.flyingCircleRotations.reverse(); };
        });
        this.sceneRotationQueue.add(() => delay(2000));
      }
    }
  }

  onPrepareScene = scene => {
    scene.createDefaultLight();
    var defaultLight = _.last(scene.lights);
    defaultLight.intensity = 1;
    defaultLight.groundColor = new Color3.FromHexString('#383838')
    //scene.freezeActiveMeshes();
    //scene.collisionsEnabled = true;
    scene.clearColor = Color3.FromHexString('#EDEDED');

    var map = {}; //object for multiple key presses
    scene.actionManager = new ActionManager(scene);

    if (this.state.sceneDebugEnabled) {
      scene.debugLayer.show();
    }

    scene.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnKeyDownTrigger, function (evt) {
      map[evt.sourceEvent.keyCode] = evt.sourceEvent.type === "keydown";
    }));

    scene.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnKeyUpTrigger, function (evt) {
      map[evt.sourceEvent.keyCode] = evt.sourceEvent.type === "keydown";
    }));

    scene.registerAfterRender(() => {
      this.onKeyPressDown(scene, map);
    });
  }

  onSceneReady = (readyScene, canvasRef) => {
    let gui = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI", true, readyScene);
    gui.idealHeight = 872;
    gui.idealWidth = 1920;

    const canvas = readyScene.getEngine().getRenderingCanvas();
    this.canvas = canvas;
    readyScene.getEngine().setHardwareScalingLevel(constants.defaultScalingLevel);

    let cameraStartLocation = new Vector3(...constants.cameraHomeLocation);
    let cameraStartRotation = new Vector3(...constants.cameraHomeRotation);
    this.camera = BabylonUtils.buildCamera(readyScene, cameraStartLocation, cameraStartRotation);
    this.camera.attachControl(canvas, true);

    SceneLoader.ShowLoadingScreen = true;
    SceneLoader.AppendAsync("./models/", "hbc.glb", readyScene).then((scene) => {
      try {
        scene.meshes.forEach(mesh => {
          mesh.isPickable = true;
        });
        var highlightMaterial = readyScene.getMaterialByName('White').clone('highlightMaterial');
        highlightMaterial.albedoColor = new Color3.FromHexString(constants.highlightColor);
        this.setState({ highlightMaterial: highlightMaterial });
        if (constants.startInPerformanceMode) {
          this.toggleSceneOptimizer();
        }
        this.props.sceneLoaded(scene);
      } catch (error) {
        console.log(error);
      }
    });
    this.setState({ scene: readyScene, sceneOptimizer: BabylonUtils.createDefaultOptimiser(readyScene, 60), sceneUI: gui, sceneCanvasRef: canvasRef, camera: this.camera });
  }

  onCanvasResized = () => {
    if (this.state.scene && this.state.sceneCanvasRef) {
      this.setState({ canvasHeight: this.state.sceneCanvasRef.current.offsetHeight });
      this.state.scene.getEngine().resize();
      let sceneHeight = this.state.sceneCanvasRef.current.offsetHeight;
      let sceneWidth = this.state.sceneCanvasRef.current.offsetWidth;
      this.state.sceneUI.idealHeight = sceneHeight + (sceneHeight / 4);
      this.state.sceneUI.idealWidth = sceneWidth + (sceneWidth / 4);
    }
  }


  blockCanvas(shouldBlock) {
    if (this.state.scene && this.state.sceneCanvasRef) {
      if (this.state.sceneCanvasRef.current) {
        if (shouldBlock) {
          this.state.sceneCanvasRef.current.classList.add('disable-mobile-clicks');
        } else {
          this.state.sceneCanvasRef.current.classList.remove('disable-mobile-clicks');
        }
      }
    }
  }

  isInBuilding() {
    return this.state.currentBuilding !== null;
  }

  /**
   * Babylon render loop. Will be called every frame.
   * @param {BABYLON.Scene} scene 
   */
  onRender = scene => {
    /* Limit user view angle when inside a building */
    if (this.isInBuilding()) {
      var DirX = Tools.ToDegrees(this.camera.rotation.x);
      var DirY = Tools.ToDegrees(this.camera.rotation.y);
      /* Counterintuitively, this is the horizontal rotation */
      let destination = _.last(this.state.currentBuilding.animation.keyframes);
      let yUpperBound = Tools.ToDegrees(destination.rotation[1]) + 80;
      let yLowerBound = Tools.ToDegrees(destination.rotation[1]) - 80;

      DirY = DirY >= yUpperBound ? yUpperBound : DirY;
      DirY = DirY <= yLowerBound ? yLowerBound : DirY;

      /* Counterintuitively, this is the vertical rotation */
      DirX = DirX >= 45 ? 45 : DirX;
      DirX = DirX <= -45 ? -45 : DirX;

      this.camera.rotation =
        new Vector3(
          Tools.ToRadians(DirX),
          Tools.ToRadians(DirY), 0);
    }

    /* Locks hotspots position on screen relative to the camera */
    this.onScreenSolutionHotspots.forEach(hotspot => {
      hotspot.control.moveToVector3(new Vector3(...hotspot.position), scene);
    });
  }

  render() {
    return (
      <div className="Scene" >
        <ReactResizeDetector handleWidth handleHeight onResize={this.onCanvasResized} targetRef={this.state.sceneCanvasRef} />
        <SceneComponent antialias={true} onSceneReady={this.onSceneReady} onScenePrepare={this.onPrepareScene} adaptToDeviceRatio={true} onRender={this.onRender} id='Scene__canvas' />
      </div>
    );
  }

  componentDidUpdate = (prevProps) => {
  }
}


const mapStateToProps = function (state) {
  return {
    currentChallengeID: state.currentChallengeID,
    selectedSolution: state.selectedSolution,
    isCameraAnimating: state.isCameraAnimating
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(Scene);