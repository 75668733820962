import React from 'react';
import './footer.scss';
import hc_logo from '../../images/hc_logo_white.svg';
function Footer() {
    return (
        <div className="Footer container-fluid">
            <div className="row justify-content-center">
                <div className="col-12 col-md-9 d-flex justify-content-center">
                    <img className="Footer__logo" src={hc_logo} alt="Hybrid City Footer Logo"></img>
                    <div className="Footer__text">© Westcoast Limited 2020. All rights reserved. Registered in England No. 1816587. VAT Registration No. GB&nbsp;115&nbsp;0919&nbsp;37.<br></br>Full details can be found by reading our <a href="https://www.westcoast.co.uk/about-us/Cookie_Policy.html">Cookie Policy</a>, <a href="http://cdn.westcoast.co.uk/pdfs/Westcoast%20Ltd%20Privacy%20Notice%20-%20Website%20Facing%20-%20FINAL%20-%202.pdf">Privacy Policy</a> and <a href="https://www.westcoast.co.uk/Admin/Downloads/Westcoast%20GDPR%20Policy%20Statement%20%20Advisory.pdf">GDPR Policy</a>, <a href="https://www.westcoast.co.uk/about-us/terms-and-conditions.html">Terms &amp; Conditions</a> or by contacting <a href="mailto:marketing.compliance@westcoast.co.uk">marketing.compliance@westcoast.co.uk</a></div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
