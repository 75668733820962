import { createSlice, configureStore } from '@reduxjs/toolkit'

const hcSlice = createSlice({
    name: 'hc',
    initialState: {
        currentChallengeID: null,
        selectedSolution: null,
        isCameraAnimating: false
    },
    reducers: {
        setCurrentChallengeID: (state, action) => {
            state.currentChallengeID = action.payload.id;
        },
        setCurrentSolution: (state, action) => {
            state.selectedSolution = action.payload.solution;
        },
        setIsCameraAnimating: (state, action) => {
            state.isCameraAnimating = action.payload.isCameraAnimating;
        }
    }
})

const store = configureStore({ reducer: hcSlice.reducer })

export const { setCurrentChallengeID, setCurrentSolution, setIsCameraAnimating } = hcSlice.actions

export { hcSlice, store }