import { createBrowserHistory } from 'history';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import { Provider } from "react-redux";
import {
  Router,
  Switch,
  withRouter,
  Route
} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import hc_logo from '../../images/hc_logo.svg';
import intel_logo from '../../images/intel_logo.svg';
import { ReactComponent as HelpIcon } from '../../images/question-circle-regular.svg';
import { ReactComponent as OptimizeIcon } from '../../images/speedo.svg';
import { ReactComponent as RotateIcon } from '../../images/rotate-camera.svg';
import { store } from "../../redux/store";
import Scene from '../scene';
import Home from '../home';
import Challenge from '../challenge';
import Solution from '../solution';
import * as _get from 'lodash/get';
import './app.scss';




const history = createBrowserHistory({ basename: process.env.PUBLIC_URL });

class App extends React.Component {

  constructor(props) {
    super();
    this.sceneComponent = React.createRef();
    this.state = {
      scene: null,
      isSceneLoaded: false,
      prevPage: '',
      currentPageMatch: null,
      sceneOptimizerStarted: false,
      canvasHeight: 0
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.setState({ prevPage: this.props.location });
    }
  }

  onAnimationEnded(page) {
  }

  onSceneOptimizerStarted = (hasStarted) => {
    this.setState({ sceneOptimizerStarted: hasStarted });
  }

  rotateScene = () => {
    let sceneComponent = _get(this.sceneComponent, 'current', null);
    if (sceneComponent) {
      sceneComponent.rotateScene();
    }
  }

  toggleSceneOptimizer = () => {
    let sceneComponent = _get(this.sceneComponent, 'current', null);
    if (sceneComponent) {
      sceneComponent.toggleSceneOptimizer();
    }
  }

  onSceneLoaded = (loadedScene) => {
    this.setState({ scene: loadedScene, isSceneLoaded: true });
  }


  render() {
    return (
      <Provider store={store}>
        <Router history={history} basename={process.env.PUBLIC_URL}>
          <>
            <Scene ref={this.sceneComponent} navigator={history} urlParams={this.props.match} sceneLoaded={this.onSceneLoaded} onSceneOptimizerStarted={this.onSceneOptimizerStarted} onAnimationEnded={this.onAnimationEnded} canvasHeight={this.state.canvasHeight} />
            <div className="App d-flex flex-fill h-auto w-100">

              <div className="container-fluid flex-fill d-flex flex-column h-auto">
                <div className="row justify-content-center h-auto">
                  <div className="col-12 col-md-9 position-relative App__logo-row">
                    <a href={process.env.PUBLIC_URL}><img className="App__page-logo-left" src={hc_logo} alt="Hybrid City Logo" /></a>
                    <img className="App__page-logo-right" src={intel_logo} alt="Intel Logo" />
                  </div>
                </div>
                <div className="row justify-content-center flex-fill h-auto">
                  <div className="col-12 col-md-9 d-flex flex-column disable-clicks">
                    <Switch>
                      <Route exact path="/" render={() => <Home scene={this.state.scene} isSceneLoaded={this.state.isSceneLoaded ? 1 : 0} sceneComponent={this.sceneComponent} navigator={history} urlParams={''} />} />
                      <Route exact path="/:id" render={(props) => <Challenge scene={this.state.scene} isSceneLoaded={this.state.isSceneLoaded ? 1 : 0} sceneComponent={this.sceneComponent} navigator={history} urlParams={props.match.params} />} />
                      <Route exact path="/:id/:verticalid/:buildingid" render={(props) => <Solution scene={this.state.scene} isSceneLoaded={this.state.isSceneLoaded ? 1 : 0} sceneComponent={this.sceneComponent} navigator={history} urlParams={props.match.params} />} />
                    </Switch>
                  </div>
                  <ReactTooltip id='rotateTip' type='light' effect='solid'>
                    <span>Click to rotate around the city</span>
                  </ReactTooltip>
                  <span data-tip data-for='rotateTip' className="App__rotate-icon-tooltip"><RotateIcon className="App__ui-icon App__rotate-icon" alt="Rotate Camera" onClick={this.rotateScene} /></span>
                  <ReactTooltip id='helpTip' type='light' effect='solid'>
                    <span>Click and drag on the city to look around</span>
                  </ReactTooltip>
                  <span data-tip data-for='helpTip' className="App__help-icon-tooltip"><HelpIcon className="App__ui-icon App__help-icon" alt="Get help" /></span>
                  <ReactTooltip id='performanceTip' type='light' effect='solid'>
                    <span>{this.state.sceneOptimizerStarted ? 'Disable' : 'Enable'} high performance mode ({this.state.sceneOptimizerStarted ? 'Improves' : 'Reduces'} fidelity)</span>
                  </ReactTooltip>
                  <span data-tip data-for='performanceTip' className="App__optimize-icon-tooltip"><OptimizeIcon className="App__ui-icon App__optimize-icon" onClick={this.toggleSceneOptimizer} style={{ fill: this.state.sceneOptimizerStarted ? "#C20065" : "#000000", transform: this.state.sceneOptimizerStarted ? "" : "scale(-1,1)" }} alt="Toggle Performance Mode" /></span>


                </div>
              </div>
            </div >
          </>
        </Router>
      </Provider>

    );
  }

  componentDidMount = () => {
  }

}




export default withRouter(App);