import challengeDB from '../database/challenge-data.json'
import verticalDB from '../database/vertical-data.json';

class HCDB {

    fetchAll = () => {
        return challengeDB;
    }

    fetchChallenge = (id) => {
        return challengeDB.find(({ _id }) => _id === id);
    }

    fetchVerticals = (challenge) => {
        let array = [];
        challenge.verticals.forEach(vertical => {
            array.push(verticalDB.find(({ _id }) => _id === vertical._id));
        });
        return array;
    }

    fetchVertical(id) {
        return verticalDB.find(({ _id }) => _id === id);
    }

    fetchSolutionsForVertical(challenge, id) {
        return challenge.verticals.find(({ _id }) => _id === id)?.solutions;
    }

    fetchBuildings = (vertical) => {
        return vertical.buildings;
    }

    fetchBuilding(vertical, id) {
        return vertical.buildings.find(({ _id }) => _id === id);
    }

    get = () => {
        return this.result;
    }

}
export default HCDB;