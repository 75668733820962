import { Engine } from '@babylonjs/core/Engines/engine'
import { Scene } from '@babylonjs/core/scene'
import React, { useEffect, useRef, useState } from 'react';

let loadingScreenDiv = window.document.getElementById('babylonjsLoadingDiv');

function hybridCityLoadingScreen() {
}
hybridCityLoadingScreen.prototype.displayLoadingUI = function () {
    loadingScreenDiv.style.opacity = "1";
};
hybridCityLoadingScreen.prototype.hideLoadingUI = function () {
    loadingScreenDiv.style.opacity = "0";
};

var hcLoadingScreen = new hybridCityLoadingScreen();

export default (props) => {
    const reactCanvas = useRef(null);
    const { antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady, onScenePrepare, ...rest } = props;

    const [loaded, setLoaded] = useState(false);
    const [scene, setScene] = useState(null);

    useEffect(() => {
        if (window) {
            const resize = () => {
                if (scene) {
                    scene.getEngine().resize();
                }
            }
            window.addEventListener('resize', resize);

            return () => {
                window.removeEventListener('resize', resize);
            }
        }
    }, [scene]);

    useEffect(() => {
        if (!loaded) {
            setLoaded(true);
            const engine = new Engine(reactCanvas.current, antialias, engineOptions, adaptToDeviceRatio);
            engine.loadingScreen = hcLoadingScreen;
            const scene = new Scene(engine, sceneOptions);
            props.onScenePrepare(scene);
            setScene(scene);
            if (scene.isReady()) {
                props.onSceneReady(scene, reactCanvas)
            } else {
                scene.onReadyObservable.addOnce(scene => props.onSceneReady(scene, reactCanvas));
            }

            engine.runRenderLoop(() => {
                if (typeof onRender === 'function') {
                    onRender(scene);
                }
                scene.render();
            })
        }

        return () => {
            if (scene !== null) {
                scene.dispose();
            }
        }
    }, [reactCanvas])

    return (
        <canvas touch-action="none" ref={reactCanvas} {...rest} />
    );
}