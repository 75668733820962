import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import back_icon from '../../images/back-icon.svg';
import close_icon from '../../images/cross-icon.svg';
import expand_icon from '../../images/expand-icon.svg';
import { setCurrentChallengeID, setIsCameraAnimating, store as globalState } from "../../redux/store";
import HCDB from '../../utils/data-store';
import * as _get from 'lodash/get';
import * as BabylonUtils from '../../utils/babylon-utils';
import './challenge.scss';

class Challenge extends React.Component {

  constructor(props) {
    super();
    this.db = new HCDB();
    this.state = {
      prevPage: '',
      challenge: this.db.fetchChallenge(props.urlParams.id),
      isCollapsed: false
    }
  }

  onPageLoaded() {
    let verticals = this.db.fetchVerticals(this.state.challenge);
    globalState.dispatch(setCurrentChallengeID({ id: this.props.urlParams.id }));


    let sceneComponent = _get(this.props.sceneComponent, 'current', null);
    if (sceneComponent) {
      /* Highlighted necessary buildings */
      verticals.forEach(vertical => {
        this.db.fetchBuildings(vertical).forEach(building => {
          this.props.sceneComponent.current.highlightBuilding(vertical, building);
        });
      });

      /* Start flying camera to staging position */
      if (this.state.challenge) {
        let animation = sceneComponent.createCameraAnimationSequence(this.state.challenge);
        if (animation) {
          globalState.dispatch(setIsCameraAnimating({ isCameraAnimating: true }));
          animation.then(() => {
            globalState.dispatch(setIsCameraAnimating({ isCameraAnimating: false }));
          });
        }
      }
    }


  }

  /* Page has been loaded directly, wait until the scene is loaded before prepping. */
  onSceneLoaded(scene) {
    this.onPageLoaded();
  }

  toggleCollapse = () => {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  }

  render() {
    return (
      <>
        <div className="d-flex flex-row mt-5">
          <Link to="/" className="Challenge__back no-select"><div className="Challenge__back-container"><img src={back_icon} alt="Go back" /> <h2 className="text-black mb-0">Back to city overview</h2></div></Link>
        </div>
        <div className="d-flex flex-column h-100 mt-3 mb-5">
          <div className="content-pane Challenge__content-pane Challenge__challenge-page">
            <div className="Challenge__nav no-select" onClick={this.toggleCollapse}>
              <img src={this.state.isCollapsed ? expand_icon : close_icon} alt="Collapse pane" />
            </div>
            {!this.state.isCollapsed && <div className="Challenge__content" >
              <h1 className="text-black-italic">Challenge: {this.state.challenge.name}.</h1>
              <h2 className="text-semi-bold mt-3">{this.state.challenge.description}</h2>
            </div>}
            {this.state.isCollapsed && <div className="Challenge__content collapsed"> <h2 className="text-semi-bold mb-0">Click the arrow to expand</h2> </div>}
          </div >
        </div>
      </>
    );
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.isSceneLoaded !== this.props.isSceneLoaded && this.props.isSceneLoaded) {
      this.onSceneLoaded(this.props.scene);
    }
  }

  componentDidMount = () => {
    /* Page has been navigated to. Scene should be ready to go. */
    if (this.props.isSceneLoaded) {
      this.onPageLoaded();
    }
  }

}

const mapStateToProps = function (state) {
  return {
    currentChallengeID: state.currentChallengeID,
  }
}

export default connect(mapStateToProps)(withRouter(Challenge));