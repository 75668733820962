import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as DropDownOffIcon } from '../../images/dropdown-off.svg';
import { ReactComponent as DropDownOnIcon } from '../../images/dropdown-on.svg';
import { setCurrentChallengeID, setIsCameraAnimating, store as globalState } from "../../redux/store";
import * as constants from '../../utils/const';
import * as _get from 'lodash/get';
import HCDB from '../../utils/data-store';
import './home.scss';
import * as BabylonUtils from '../../utils/babylon-utils';

class Home extends React.Component {

  constructor(props) {
    super();
    this.state = {
      prevPage: '',
      selectedCategoryLabel: '',
      securityDropdownExpanded: false,
      dataDropdownExpanded: false,
      bizDevDropdownExpanded: false,
      efficiencyDropdownExpanded: false
    }
    this.db = new HCDB();
  }

  playIntroAnimation() {
    let sceneComponent = _get(this.props.sceneComponent, 'current', null);
    let introAnimation = {
      "animation": {
        "duration": 30, "keyframes": [{
          "time": 15,
          "position": [2163.97, 2477.00, -3254.79],
          "rotation": [0.59, -0.45, 0.00]
        }, {
          "time": 30,
          "position": constants.cameraHomeLocation,
          "rotation": constants.cameraHomeRotation
        }]
      }
    };
    sceneComponent.createCameraAnimationSequence(introAnimation);
  }

  onSceneLoaded(scene) {
    globalState.dispatch(setCurrentChallengeID({ id: null }));
    let sceneComponent = _get(this.props.sceneComponent, 'current', null);
    if (sceneComponent) {
      sceneComponent.clearBuildingHighlights();
    }
    this.playIntroAnimation();
    setTimeout(function () {

      /* sceneComponent.createCameraAnimation([2163.97, 2477.00, -3254.79], [0.59, -0.45, 0.00], 0.1);
      sceneComponent.createCameraAnimation([1095.88, 2477.00, -3519.68], [0.61, -0.07, 0.00], 0.1); */
    }, 0);


  }

  trackClick(category, label) {
    window.gtag('event', 'click', { 'event_category': category, 'event_label': label });
  }

  toggleDropdown(id) {
    switch (id) {
      case 'security':
        let newStateSec = !this.state.securityDropdownExpanded;
        this.setState({ selectedCategoryLabel: newStateSec ? 'Security' : null });
        this.setState({ securityDropdownExpanded: newStateSec });
        this.setState({ dataDropdownExpanded: false });
        this.setState({ bizDevDropdownExpanded: false });
        this.setState({ efficiencyDropdownExpanded: false });
        break;
      case 'data':
        let newStateData = !this.state.dataDropdownExpanded;
        this.setState({ selectedCategoryLabel: newStateData ? 'Data' : null });
        this.setState({ securityDropdownExpanded: false });
        this.setState({ dataDropdownExpanded: newStateData });
        this.setState({ bizDevDropdownExpanded: false });
        this.setState({ efficiencyDropdownExpanded: false });

        break;
      case 'bizdev':
        let newStateBD = !this.state.bizDevDropdownExpanded;
        this.setState({ selectedCategoryLabel: newStateBD ? 'Business Development' : null });
        this.setState({ securityDropdownExpanded: false });
        this.setState({ dataDropdownExpanded: false });
        this.setState({ bizDevDropdownExpanded: newStateBD });
        this.setState({ efficiencyDropdownExpanded: false });

        break;
      case 'efficiency':
        let newStateEff = !this.state.efficiencyDropdownExpanded;
        this.setState({ selectedCategoryLabel: newStateEff ? 'Efficiency' : null });
        this.setState({ securityDropdownExpanded: false });
        this.setState({ dataDropdownExpanded: false });
        this.setState({ bizDevDropdownExpanded: false });
        this.setState({ efficiencyDropdownExpanded: newStateEff });
        break;
      default:
        this.setState({ selectedCategoryLabel: '' });
        this.setState({ securityDropdownExpanded: false });
        this.setState({ dataDropdownExpanded: false });
        this.setState({ bizDevDropdownExpanded: false });
        this.setState({ efficiencyDropdownExpanded: false });
        break;
    }
  }

  render() {
    return (
      <div className="content-pane Home__content-pane Home__home-page" >
        <h1 className="text-black-italic">Welcome to the Hybrid cITy.</h1>
        <h2 className="text-semi-bold">An interactive site designed to help you find the right hybrid solutions for your customers. Whatever the challenge, whatever the environment, there's a solution to be found.</h2>
        <p>Combining cloud with on-premises IT brings unparalleled flexibility and scalability to your business. But the resulting hybrid cloud environment introduces complexity that can impact productivity.</p>
        <p>HPE software-defined and cloud solutions reduce operational friction, offering a cloud-like experience across your entire infrastructure. With software-enabled automation and simplified hybrid cloud management to streamline and speed operations, you can focus on new projects that deliver a competitive edge.</p>
        <h3 className="text-bold-italic">Select your most common challenge:</h3>
        <div className="Home__challenge-group-btns d-flex flex-row flex-wrap">
          <div className={'Home__dropdown ' + (this.state.securityDropdownExpanded ? 'expanded' : '')} onClick={() => this.toggleDropdown('security')}>
            <button className="btn btn-hc green text-black w-100" type="button">
              <p>Security</p>{this.state.securityDropdownExpanded ? <DropDownOnIcon className="dropdown-icon" alt="Security dropdown open" /> : <DropDownOffIcon className="dropdown-icon" alt="Security dropdown closed" />}
            </button>
            <div className="line"></div>
          </div>
          <div className={'Home__dropdown ' + (this.state.dataDropdownExpanded ? 'expanded' : '')} onClick={() => this.toggleDropdown('data')}>
            <button className="btn btn-hc green text-black w-100" type="button">
              <p>Data</p>{this.state.dataDropdownExpanded ? <DropDownOnIcon className="dropdown-icon" alt="Data dropdown open" /> : <DropDownOffIcon className="dropdown-icon" alt="Data dropdown closed" />}
            </button>
            <div className="line"></div>
          </div>
          <div className={'Home__dropdown ' + (this.state.bizDevDropdownExpanded ? 'expanded' : '')} onClick={() => this.toggleDropdown('bizdev')}>
            <button className="btn btn-hc green text-black w-100" type="button">
              <p>Business Development</p>{this.state.bizDevDropdownExpanded ? <DropDownOnIcon className="dropdown-icon" alt="Business Development dropdown open" /> : <DropDownOffIcon className="dropdown-icon" alt="Business Development dropdown closed" />}
            </button>
            <div className="line"></div>
          </div>
          <div className={'Home__dropdown ' + (this.state.efficiencyDropdownExpanded ? 'expanded' : '')} onClick={() => this.toggleDropdown('efficiency')}>
            <button className="btn btn-hc green text-black w-100" type="button">
              <p>Efficiency</p>{this.state.efficiencyDropdownExpanded ? <DropDownOnIcon className="dropdown-icon" alt="Efficiency dropdown open" /> : <DropDownOffIcon className="dropdown-icon" alt="Efficiency dropdown closed" />}
            </button>
            <div className="line"></div>
          </div>
        </div>
        <h3 className="Home__selected-category-label text-bold-italic mt-2 mb-0">{this.state.selectedCategoryLabel}</h3>
        <div className="Home__challenge-btns mr-3 d-flex flex-row ">

          {this.state.securityDropdownExpanded && <div className="Home__dropdown-items">
            <div className="btn-overflow-wrapper">
              <Link to="/security_secure_access" className="btn btn-hc text-black" onClick={() => { this.trackClick('Challenge', 'Security/Secure Access') }} ><p>Security/Secure Access</p></Link>
            </div>
            <div className="btn-overflow-wrapper">
              <Link to="/analytics" className="btn btn-hc text-black" onClick={() => { this.trackClick('Challenge', 'Real-time Analytics') }}><p>Real-time Analytics</p></Link>
            </div>
          </div>}
          {this.state.dataDropdownExpanded && <div className="Home__dropdown-items">
            <div className="btn-overflow-wrapper">
              <Link to="/connectivity" className="btn btn-hc text-black" onClick={() => { this.trackClick('Challenge', 'Connectivity') }}><p>Connectivity</p></Link>
            </div>
            <div className="btn-overflow-wrapper">
              <Link to="/optimisation" className="btn btn-hc text-black" onClick={() => { this.trackClick('Challenge', 'Network optimisation and provisioning') }}><p>Network optimisation and provisioning</p></Link>
            </div>
          </div>}
          {this.state.bizDevDropdownExpanded && <div className="Home__dropdown-items">
            <div className="btn-overflow-wrapper">
              <Link to="/latency" className="btn btn-hc text-black" onClick={() => { this.trackClick('Challenge', 'Latency/Business continuity/Minimise risk') }}><p>Latency/Business continuity/Minimise risk</p></Link>
            </div>
            <div className="btn-overflow-wrapper">
              <Link to="/budget_pressures_costs" className="btn btn-hc text-black" onClick={() => { this.trackClick('Challenge', 'Budgets pressures/Controlling costs') }}><p>Budgets pressures/Controlling costs</p></Link>
            </div>
          </div>}
          {this.state.efficiencyDropdownExpanded && <div className="Home__dropdown-items">
            <div className="btn-overflow-wrapper">
              <Link to="/performance" className="btn btn-hc text-black" onClick={() => { this.trackClick('Challenge', 'Performance') }}><p>Performance</p></Link>
            </div>
            <div className="btn-overflow-wrapper">
              <Link to="/agility" className="btn btn-hc text-black" onClick={() => { this.trackClick('Challenge', 'Agility/flexibility') }}><p>Agility/flexibility</p></Link>
            </div>
            <div className="btn-overflow-wrapper">
              <Link to="/efficiency" className="btn btn-hc text-black" onClick={() => { this.trackClick('Challenge', 'Business efficiency') }}><p>Business efficiency</p></Link>
            </div>
          </div>}

        </div>
      </div>
    );
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.isSceneLoaded !== this.props.isSceneLoaded && this.props.isSceneLoaded) {
      this.onSceneLoaded(this.props.scene);
    }
  }

  componentDidMount = () => {
    let sceneComponent = _get(this.props.sceneComponent, 'current', null);
    if (sceneComponent) {
      sceneComponent.clearBuildingHighlights();
      let homeDestination = {
        "animation": {
          "duration": 2, "keyframes": [{
            "time": 2,
            "position": constants.cameraHomeLocation,
            "rotation": constants.cameraHomeRotation
          }]
        }
      };
      let animation = sceneComponent.createCameraAnimationSequence(homeDestination);
      if (animation) {
        globalState.dispatch(setIsCameraAnimating({ isCameraAnimating: true }));
        animation.then(() => {
          globalState.dispatch(setIsCameraAnimating({ isCameraAnimating: false }));
          this.playIntroAnimation();
        });
      }
    }
    globalState.dispatch(setCurrentChallengeID({ id: null }));
  }

}

const mapStateToProps = function (state) {
  return {
    currentChallengeID: state.currentChallengeID,
  }
}

export default connect(mapStateToProps)(Home);