import { UniversalCamera } from '@babylonjs/core/Cameras/universalCamera'
import { Vector3 } from '@babylonjs/core/Maths/math'
import { Animation } from '@babylonjs/core/Animations/animation';
import { TextureOptimization, ShadowsOptimization, SceneOptimizerOptions, SceneOptimizer, HardwareScalingOptimization, ParticlesOptimization, PostProcessesOptimization, RenderTargetsOptimization } from '@babylonjs/core/Misc/sceneOptimizer';
import { BezierCurveEase, CubicEase, EasingFunction } from '@babylonjs/core/Animations/easing';
import * as GUI from 'babylonjs-gui';
import KEY_CODE from '../classes/keycodes';
import { cameraHomeLocation, enableFlyingCamera } from './const';
export function createDefaultOptimiser(scene, targetFPS) {
    let sceneOptimizerOptions = new SceneOptimizerOptions(targetFPS, 2000)
    sceneOptimizerOptions.optimizations.push(new ShadowsOptimization(3));
    sceneOptimizerOptions.optimizations.push(new TextureOptimization(0, 256));
    sceneOptimizerOptions.optimizations.push(new RenderTargetsOptimization(1));
    sceneOptimizerOptions.optimizations.push(new PostProcessesOptimization(2));
    sceneOptimizerOptions.optimizations.push(new ParticlesOptimization(2));
    sceneOptimizerOptions.optimizations.push(new HardwareScalingOptimization(0, 1));
    return new SceneOptimizer(scene, sceneOptimizerOptions);
}

export function hasAlbedo(mesh, albedoColor) {
    if (mesh.material != null && mesh.material.albedoColor != null && mesh.material.albedoColor.equals(albedoColor)) {
        return true;
    }
    return false;
}

export function createHotspotButton(solution, building, callback) {
    var imageBtn = new GUI.Button.CreateImageOnlyButton(solution.name.replace(/\s+/g, '') + '_hotspotbtn', process.env.PUBLIC_URL + "/models/images/hotspot-sprite-sheet.png");
    imageBtn.width = "66px";
    imageBtn.height = "66px";
    imageBtn.thickness = 0;
    imageBtn.hoverCursor = 'pointer';
    imageBtn.image.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    imageBtn.alpha = 0;
    imageBtn.onPointerDownObservable.add(function (evt) {
        if (callback) {
            callback(solution, building, imageBtn);
        }
    });

    imageBtn.image.cellId = 0;
    imageBtn.image.cellHeight = 195;
    imageBtn.image.cellWidth = 195;

    setInterval(() => {
        if (imageBtn.image._source && !isHotspotActive(imageBtn)) {
            /* 29 images in sprite sheet. 0 index thus 28 */
            if (imageBtn.image.cellId < 28) imageBtn.image.cellId++;
            else imageBtn.image.cellId = 0;
        }
    }, 100);

    return imageBtn;
}

export function isHotspotActive(hotspot) {
    if (hotspot.image._source.includes('hotspot-sprite-sheet.png')) {
        return false;
    }
    return true;
}

export function toggleHotspot(hotspot, isActive = null) {
    hotspot.image.cellId = 0;
    let imgBasePath = process.env.PUBLIC_URL;
    let newState = isActive !== null ? isActive : !isHotspotActive(hotspot);
    let stateImage = newState ? '/models/images/hotspot-active.png' : '/models/images/hotspot-sprite-sheet.png';
    hotspot.image.source = imgBasePath + stateImage;
}

export function createOverlayButton(guiContext, vertical, building, callback) {
    let text = vertical.name + ", " + building.name + "   ";
    let defaultPadding = 10;

    var panel = new GUI.StackPanel(building.mesh + "_panel");
    panel.alpha = 0;
    panel.height = defaultPadding + 40 + "px";
    panel.hoverCursor = 'pointer';
    panel.background = "white";
    panel.isVertical = false;
    panel.isPointerBlocker = true;

    var button = new GUI.Button(vertical.name.replace(/\s+/g, '') + "_button");

    button.color = "black";
    button.background = "red";
    button.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;

    button.thickness = 0;
    button.cornerRadius = 0;

    panel.onPointerDownObservable.add(function (evt) {
        callback(vertical, building);
    });

    var textBlock = new GUI.TextBlock(vertical.name.replace(/\s+/g, '') + "_button_text", text);
    textBlock.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
    textBlock.textVerticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    textBlock.fontWeight = "700";
    textBlock.fontStyle = "italic";
    textBlock.paddingTop = 5 + "px";
    textBlock.paddingLeft = defaultPadding + "px";

    guiContext.font = "italic 20px 'Source Sans Pro'";
    textBlock.fontSize = 20;
    let textWidth = Math.max(0, Math.floor(guiContext.measureText(text).width) + (defaultPadding + 30));
    let textHeight = guiContext.measureText(text).offsetHeight;
    button.width = (textWidth + textWidth / 4) + "px";
    button.height = "20px";
    textBlock.width = textWidth + "px";
    panel.width = button.width;

    var image = new GUI.Image("but", process.env.PUBLIC_URL + '/models/images/challenge-plus.png');
    image.width = defaultPadding + 20 + "px";
    image.height = 20 + "px";
    image.color = "black";
    image.background = "black";
    image.paddingLeft = defaultPadding + "px";
    image.stretch = GUI.Image.STRETCH_FILL;
    image.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    image.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
    panel.addControl(image);
    panel.addControl(textBlock);

    return panel;
}

export function makeCancelablePromise(promise) {
    let hasCanceled_ = false;
    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            val => hasCanceled_ ? reject({ isCanceled: true }) : resolve(val),
            error => hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
        );
    });
    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
    };
}

export function CreateAndStartFadeInAnimation(scene, control, callback = null, speedRatio = 1) {
    var ease = new CubicEase();
    ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
    var animationHideText = new Animation("VisAnim_" + control.name, "alpha", 60, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    animationHideText.setEasingFunction(ease);
    var keys = [];
    keys.push({
        frame: 0,
        value: 0
    });
    keys.push({
        frame: 60,
        value: 0.85
    });
    animationHideText.setKeys(keys);
    control.animations = [];
    control.animations.push(animationHideText);
    scene.beginAnimation(control, 0, 60, false, speedRatio, callback, undefined, false);
    return animationHideText;
}

/**
 * Creates translation and rotation animation based on an array of keyframes. 
 * 
 * @param {BABYLON.Scene} scene 
 * @param {BABYLON.Camera} camera 
 * @param {[]} destination 
 * @returns Promise object representing the animation. Will be called when animation completes
 * 
 */
export function createCameraAnimationSequence(scene, camera, destination) {
    if (camera && scene) {
        var animationcamera = new Animation(
            "hcPositionAnimation",
            "position",
            60,
            Animation.ANIMATIONTYPE_VECTOR3,
            Animation.ANIMATIONLOOPMODE_CONSTANT
        );
        var positionKeyFrames = [];
        if (!hasFrameZero(destination.animation.keyframes)) {
            positionKeyFrames.push({
                frame: 0,
                value: camera.position.clone(),
            });
        }
        destination.animation.keyframes.forEach(keyFrame => {
            positionKeyFrames.push({
                frame: keyFrame.time * 60,
                value: new Vector3(...keyFrame.position),
            });
        });
        animationcamera.setKeys(positionKeyFrames);
        animationcamera.setEasingFunction(new BezierCurveEase(0.42, 0, 0.58, 1));
        var rotationAnimationCamera = new Animation(
            "hcRotationAnimation",
            "rotation",
            60,
            Animation.ANIMATIONTYPE_VECTOR3,
            Animation.ANIMATIONLOOPMODE_CONSTANT
        );
        var rotationKeyFrames = [];
        if (!hasFrameZero(destination.animation.keyframes)) {
            rotationKeyFrames.push({
                frame: 0,
                value: camera.rotation.clone(),
            });
        }
        destination.animation.keyframes.forEach(keyFrame => {
            rotationKeyFrames.push({
                frame: keyFrame.time * 60,
                value: new Vector3(...keyFrame.rotation),
            });
        });
        rotationAnimationCamera.setKeys(rotationKeyFrames);
        rotationAnimationCamera.setEasingFunction(new BezierCurveEase(0.42, 0, 0.58, 1));
        camera.animations = [animationcamera, rotationAnimationCamera];
        let animationTotalFrames = destination.animation.duration * 60;
        let animation = scene.beginAnimation(camera, 0, animationTotalFrames, false, 1);
        return animation.waitAsync();
    }
    return null;
}

/**
 * 
 * Move camera to a position with a specific rotation. This is a one time movement. 
 * 
 * @param {BABYLON.Scene} scene 
 * @param {BABYLON.Camera} camera 
 * @param {number[]} position 
 * @param {number[]} rotation 
 */
export function createCameraAnimation(scene, camera, position, rotation, speedRatio = 1) {
    if (camera && scene) {
        var animationcamera = new Animation(
            "hcMoveCamAnimation_" + Date.now(),
            "position",
            60,
            Animation.ANIMATIONTYPE_VECTOR3,
            Animation.ANIMATIONLOOPMODE_CONSTANT
        );
        var positionKeyFrames = [];
        positionKeyFrames.push({
            frame: 0,
            value: camera.position.clone(),
        });
        positionKeyFrames.push({
            frame: 2 * 60,
            value: new Vector3(...position),
        });
        animationcamera.setKeys(positionKeyFrames);
        animationcamera.setEasingFunction(new BezierCurveEase(0.42, 0, 0.58, 1));
        var rotationAnimationCamera = new Animation(
            "hcMoveCamRotationAnimation_" + Date.now(),
            "rotation",
            60,
            Animation.ANIMATIONTYPE_VECTOR3,
            Animation.ANIMATIONLOOPMODE_CONSTANT
        );
        var rotationKeyFrames = [];
        rotationKeyFrames.push({
            frame: 0,
            value: camera.rotation.clone(),
        });
        rotationKeyFrames.push({
            frame: 2 * 60,
            value: new Vector3(...rotation),
        });
        rotationAnimationCamera.setKeys(rotationKeyFrames);
        rotationAnimationCamera.setEasingFunction(new BezierCurveEase(0.42, 0, 0.58, 1));
        camera.animations = [];
        camera.animations.push(animationcamera);
        camera.animations.push(rotationAnimationCamera);
        let animationTotalFrames = 2 * 60;
        scene.beginAnimation(camera, 0, animationTotalFrames, false, speedRatio, false, false, false);
    }
}



export function hasFrameZero(keyFrames) {
    return keyFrames.some(keyFrame => 0 === keyFrame.frame);
}

export function buildCamera(scene, startLocation, startRotation) {
    let camera = new UniversalCamera("camera1", startLocation, scene);
    camera.rotation = startRotation;
    camera.angularSensibility = 1000;
    camera.touchAngularSensibility = 10000;
    camera.touchMoveSensibility = 99999999999999999;
    camera.inertia = 0.1;
    camera.maxZ = 15000;
    if (enableFlyingCamera) {
        camera.keysUp.push(KEY_CODE.KEY_W);
        camera.keysDown.push(KEY_CODE.KEY_S);
        camera.keysLeft.push(KEY_CODE.KEY_A);
        camera.keysRight.push(KEY_CODE.KEY_D);
    }
    camera.speed = 100;
    camera.fov = 1.2;
    camera.checkCollisions = false;
    return camera;
}